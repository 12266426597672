import { template as template_b299d9067da245998e64ccb65880c859 } from "@ember/template-compiler";
const WelcomeHeader = template_b299d9067da245998e64ccb65880c859(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
