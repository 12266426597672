import { template as template_9f2caac40b03406a95290b080640553f } from "@ember/template-compiler";
const FKLabel = template_9f2caac40b03406a95290b080640553f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
